import React, { useState } from 'react';

import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    SelectProps,
    MenuItem,
    InputAdornment,
    SelectChangeEvent,
    ListItemIcon,
    ListItemText,
    Divider
} from '@mui/material';

import { IMenuItem } from '@luxon/interfaces';
import { useFormV2Context } from './Form.context';
import { ProgressSpinner } from '@luxon/components';
import { camelCaseToUserText } from '@luxon/utils';

interface IFormSelectInputProps<T> extends Omit<SelectProps, 'label' | 'name' | 'error'> {
    label?: string;
    name: keyof T;
    options: IMenuItem[];

    startIcon?: JSX.Element;
    isLoading?: boolean;
    showDisabledItems?: boolean;
    helperText?: string;
}
export function FormSelectInput<T>(props: IFormSelectInputProps<T>) {

    const {
        label,
        name,
        options,
        startIcon,
        isLoading,
        showDisabledItems,
        helperText,
        ...baseInputProps
    } = props;

    const [hasFocus, setHasFocus] = useState(false);

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors
    } = useFormV2Context<T>();

    const handleSelectionChange = (e: SelectChangeEvent<unknown>) => {
        const newValue = e.target.value;
        handleFormChange(name, newValue);
    };

    const getIcon = (icon: JSX.Element) => {
        const color = formErrors[name] ? 'error' : '';
        return React.cloneElement(icon, {
            color
        });
    };

    const buildStartIcon = () => !startIcon ? null : (
        <InputAdornment position='start'>
            {getIcon(startIcon)}
        </InputAdornment>
    );

    const buildEndIcon = () => !isLoading ? null : (
        <InputAdornment position='end'>
            <ProgressSpinner size={20} />
        </InputAdornment>
    );

    return (
        <FormControl fullWidth error={formErrors[name]} required={props.required} margin={props.margin}>
            <InputLabel
                id={String(name)}
                size={props.size === 'small' ? 'small' : 'normal'}
                shrink={!!formData[name] || hasFocus}
            >
                {label ?? camelCaseToUserText(String(name))}
            </InputLabel>
            <MuiSelect
                {...baseInputProps}
                variant={baseInputProps.variant}
                labelId={String(name)}
                value={formData[name] as any}
                label={label ?? camelCaseToUserText(String(name))}
                onChange={handleSelectionChange}
                error={formErrors[name]}
                inputProps={{
                    helperText
                }}
                disabled={props.disabled || formDisabled || isLoading}
                name={String(name)}
                startAdornment={buildStartIcon()}
                endAdornment={buildEndIcon()}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                renderValue={(selected: any) => {
                    if (Array.isArray(selected)) {
                        const matchingItems = selected
                            .map(x => options.find(y => x === (y.value ?? y.text)))
                            .map(x => x?.text);
                        return matchingItems.join(', ');
                    } else {
                        const matchingItem = options!.find(x => (x.value ?? x.text) === selected)
                        return matchingItem?.text ?? matchingItem?.content;
                    }
                }}
            >
                {
                    options!.filter(x => !x.disabled || showDisabledItems).map((option, index) => option.isDivider ? (
                        <Divider key={index} />
                    ) : (
                        <MenuItem
                            value={option.value ?? option.text}
                            key={option.value ?? option.text ?? index}
                        >
                            {
                                option.icon && (
                                    <ListItemIcon>
                                        {option.icon}
                                    </ListItemIcon>
                                )
                            }
                            {option.content ?? (
                                <ListItemText>{option.text}</ListItemText>
                            )}
                        </MenuItem>
                    ))
                }
            </MuiSelect>
        </FormControl>
    )
};
