import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

import { useFormV2Context } from './Form.context';

import { Dayjs } from 'dayjs';
import { camelCaseToUserText } from '@luxon/utils';
import { SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';

type TDatePickerValue = Dayjs | null | undefined;

interface IFormDateInputProps<T> extends Omit<DatePickerProps<Dayjs>, 'name' | 'label' | 'disabled'> {
    name: keyof T;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    modifier?: 'startOfDay' | 'endOfDay';
    margin?: 'none' | 'dense' | 'normal';
    helperText?: string;
    sx?: SxProps<Theme>;
}
export function FormDateInput<T>(props: IFormDateInputProps<T>) {

    const {
        name,
        label,
        disabled,
        required,
        modifier,
        helperText,
        margin,
        sx,
        ...datePickerProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
    } = useFormV2Context<T>();

    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (props.readOnly || props.disabled || formDisabled) {
            return;
        }
        setIsPickerOpen(!isPickerOpen);
    }

    const handleInputChange = (value: TDatePickerValue) => {
        let newValue = value;
        if (newValue && modifier === 'startOfDay') {
            newValue = newValue.startOf('day');
        } else if (newValue && modifier === 'endOfDay') {
            newValue = newValue.endOf('day');
        }

        handleFormChange(name, newValue);
    };

    return (
        <DatePicker
            {...datePickerProps}
            label={label ?? camelCaseToUserText(String(name))}
            format={props.format ?? 'D MMM YYYY'}
            value={formData[name] as TDatePickerValue}
            onChange={handleInputChange}
            open={isPickerOpen}
            onClose={() => setIsPickerOpen(false)}
            closeOnSelect={true}
            slotProps={{
                textField: {
                    error: formErrors[name],
                    required: required,
                    onClick: handleClick,
                    inputProps: {
                        readOnly: true
                    },
                    helperText: helperText ? (
                        <Typography variant='caption'>{helperText}</Typography>
                    ) : null,
                    margin,
                    sx
                },
                popper: {
                    popperOptions: {
                        modifiers: [ 
                            {
                                name: 'flip',
                                options: {
                                    altBoundary: false,
                                    fallbackPlacements: ['right', 'left'], // This is to prevent popper from being cut off at bottom or top, and just giving it the option to try to the left and right of the input as well
                                }
                            }
                        ]
                    }
                },
                inputAdornment: {
                    onClick: handleClick
                }
            }}
            disabled={disabled || formDisabled}
        />
    );
};