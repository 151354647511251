import React, { FunctionComponent } from 'react';

import { HttpClientError } from '@luxon/models';
import { TMethod } from '@luxon/interfaces';
import { useAppSettings, useSnackbar } from '@luxon/hooks';
import dayjs, { isDayjs } from 'dayjs';
import { DEFAULT_ERROR_MESSAGE } from '@luxon/constants';

type TQueryParams = {[key: string]: any};


interface IHttpClientRequestOptions {
    /**
     * Should the user be logged out if API returns 401 or 403 errors. Defaults to `true`
     */
    autoLogOutOnAuthError?: boolean;

    /**
     * Should the error message be shown by default. Defaults to `true`
     */
    autoShowErrorMessages?: boolean;

    /**
     * Always use the body as form
     */
    bodyAsForm?: boolean;
}

interface IHttpClientContext {
    POST: <T>(url: string, body?: any, file?: File, options?: IHttpClientRequestOptions) => Promise<T>;
    PUT: <T>(url: string, body?: any, options?: IHttpClientRequestOptions) => Promise<T>;
    DELETE: <T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions) => Promise<T>;
    GET: <T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions) => Promise<T>;
    buildUrl: (url: string, queryParams?: TQueryParams) => string;
    buildQueryParamString: (queryParams?: TQueryParams) => string;
}
const initialState: IHttpClientContext = {
    POST: () => Promise.resolve<any>({}),
    PUT: () => Promise.resolve<any>({}),
    DELETE: () => Promise.resolve<any>({}),
    GET: () => Promise.resolve<any>({}),
    buildUrl: () => '',
    buildQueryParamString: () => ''
}
export const HttpClientContext = React.createContext<IHttpClientContext>(initialState);

const extractErrorMessages = (responseBody: any): string[] => {
    if (responseBody.errors && typeof responseBody.errors === 'object' && !Array.isArray(responseBody.errors) && Object.keys(responseBody.errors).length > 0) {
        const validErrors = Object.keys(responseBody.errors).filter(x => !x.startsWith('$'));
        if (validErrors.length > 0) {
            return validErrors.flatMap(key => responseBody.errors[key]);
        }
    } else if (responseBody.detail) {
        return [responseBody.detail];
    } else if (responseBody.message) {
        return [responseBody.message];
    }

    return [DEFAULT_ERROR_MESSAGE];
};

const getHttpClientRequestOptions = (options?: IHttpClientRequestOptions): IHttpClientRequestOptions => {
    const defaultOptions: IHttpClientRequestOptions = {
        autoLogOutOnAuthError: true,
        autoShowErrorMessages: true,
        bodyAsForm: false
    };

    return {
        ...defaultOptions,
        ...(options ?? {})
    }
}

const buildFetchOptions = (method: TMethod, body?: any, file?: File, httpOptions?: IHttpClientRequestOptions): RequestInit => {
    const options: RequestInit = {
        method,
        credentials: 'include',
        headers: {
            'X-Timezone-Offset': `${new Date().getTimezoneOffset()}`,
            'X-Timezone-Name': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    };

    if (!file && body && !httpOptions?.bodyAsForm) {
        options.headers = {
            ...options.headers,
            'Content-Type': 'application/json',
        };
    }

    if (!file && body && !httpOptions?.bodyAsForm) {
        options.body = JSON.stringify(body);
    } else if (file || (body && httpOptions?.bodyAsForm)) {
        const formData = new FormData();
        if (Array.isArray(file)) {
            for (const singleFile of file) {
                formData.append('file[]', singleFile);
            }
        } else {
            formData.append('file', file);
        }
        if (body) {
            const addFormEntry = (key: string, value: any) => {
                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        const formKey = `${key}[${i}]`;
                        addFormEntry(formKey, value[i]);
                    }
                } else if (isDayjs(value)) {
                    formData.append(key, value.toISOString());
                } else if (typeof value === 'object') {
                    for (const valueKey in value) {
                        const formKey = `${key}.${valueKey}`;
                        const formValue = value[valueKey];
                        if (typeof formValue === 'object') {
                            addFormEntry(formKey, formValue);
                        } else {
                            formData.append(formKey, formValue);
                        }
                    }
                } else {
                    formData.append(key, value);
                }
            }

            for (const key in body) {
                addFormEntry(key, body[key]);
            }
        }
        options.body = formData;
    }
    return options;
};

const buildQueryParamString = (params?: {[key: string]: any}): string => {
    if (!params) {
        return '';
    }

    const queryString = Object.keys(params)
        .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
        .map(key => {
            let paramString = params[key];
            if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
                paramString = JSON.stringify(params[key]); // This will generally be Date objects, so stringify will convert to ISO date

                if (dayjs.isDayjs(params[key])) { // Check if this is a dayjs object
                    paramString = params[key].toISOString();
                } else if (dayjs(params[key]).isValid()) { // Check if this is a Date objet
                    paramString = dayjs(params[key]).toISOString();
                }
            } else if (Array.isArray(params[key])) {
                const paramStringParts: string[] = [];
                for (const item of params[key]) {
                    paramStringParts.push(`${key}=${encodeURIComponent(item)}`);
                }
                return paramStringParts.join('&');
            }
            return `${key}=${encodeURIComponent(paramString)}`;
        })
        .join('&');

    return `?${queryString}`;
};

const isDate = (text: string): boolean =>
    !!text && typeof text === 'string' && text.indexOf('T') > -1 && !isNaN(parseInt(text.substring(0, 1))) && dayjs(text).isValid()

const cleanResponse = (response: any): any => {
    if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
            response[i] = cleanResponse(response[i]);
        }
    } else if (typeof response === 'object') {
        for (const key in response) {
            if (Array.isArray(response[key])) {
                for (let i = 0; i < response[key].length; i++) {
                    response[key][i] = cleanResponse(response[key][i]);
                }
            } else if (typeof response[key] === 'object') {
                response[key] = cleanResponse(response[key]);
            } else if (isDate(response[key])) {
                response[key] = dayjs(response[key]);
            }
        }
    } else if (isDate(response)) {
        return dayjs(response);
    }
    
    return response;
};

async function downloadResponseAsFile<T>(response: Response): Promise<T> {
    const responseHeaders = Object.fromEntries(response.headers.entries());
    const contentDispositionParts = responseHeaders['content-disposition'].split(';');
    const fileNameParts = contentDispositionParts[contentDispositionParts.length - 1].split('='); 

    const blobResponse = await response.blob();
    const url = window.URL.createObjectURL(blobResponse);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileNameParts[fileNameParts.length - 1];
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();    
    a.remove();

    return { message: 'File download will start shortly' } as T
}

interface IHttpClientContextProviderProps {
    children: any;
}
export const HttpClientContextProvider: FunctionComponent<IHttpClientContextProviderProps> = (props: IHttpClientContextProviderProps) => {
    const { appSettings } = useAppSettings();
    const { showError } = useSnackbar();
    const { children } = props;

    const buildUrl = (url: string, params?: TQueryParams): string => {
        const queryString = buildQueryParamString(params);
        return `${url.startsWith('http') ? '' : appSettings.apiBaseUrl}${url}${queryString}`;
    };

    async function POST<T>(url: string, body?: any, file?: File, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('POST', url, undefined, body, file, options);
    }

    async function PUT<T>(url: string, body?: any, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('PUT', url, undefined, body, undefined, options);
    }

    async function DELETE<T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('DELETE', url, queryParams, undefined, undefined, options);
    }

    async function GET<T>(url: string, queryParams?: TQueryParams, options?: IHttpClientRequestOptions): Promise<T> {
        return await execute('GET', url, queryParams, undefined, undefined, options);
    }

    async function execute<T>(method: TMethod, url: string, queryParams?: TQueryParams, body?: any, file?: File, options?: IHttpClientRequestOptions): Promise<T> {
        const fetchUrl = buildUrl(url, queryParams);
        const httpOptions = getHttpClientRequestOptions(options);

        try {
            const fetchOptions = buildFetchOptions(method, body, file, httpOptions);

            const fetchResponse = await fetch(fetchUrl, fetchOptions);
            if (fetchResponse.status === 401 || fetchResponse.status === 403) {
                if (httpOptions.autoLogOutOnAuthError) {
                    window.location.href = '/sign-out/null';
                }

                throw new HttpClientError({
                    statusCode: fetchResponse.status,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    errorMessages: ['Fetch returned authentication error']
                });
            }

            const responseHeaders = Object.fromEntries(fetchResponse.headers.entries());
            if (responseHeaders['content-disposition'] && responseHeaders['content-disposition'].indexOf('attachment') >= 0) {
                return await downloadResponseAsFile(fetchResponse);
            }

            const response = await fetchResponse.json();
            if (fetchResponse.ok) {
                cleanResponse(response);
            }

            if (!fetchResponse.ok) {
                throw new HttpClientError({
                    statusCode: fetchResponse.status,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    rawErrors: response.errors,
                    errorMessages: extractErrorMessages(response)
                });
            }

            return response as T;
        } catch (err: any) {

            let httpClientError: HttpClientError;

            if (err instanceof HttpClientError) {
                httpClientError = err;
            } else {
                httpClientError = new HttpClientError({
                    statusCode: 500,
                    requestMethod: method,
                    requestUrl: fetchUrl,
                    errorMessages: [DEFAULT_ERROR_MESSAGE],
                    rawErrors: err
                });
            }

            console.error(httpClientError.errorResponse);

            if (httpOptions.autoShowErrorMessages) {
                showError(httpClientError.firstErrorMessage);
            }

            throw httpClientError;
        }
    }

    return (
        <HttpClientContext.Provider value={{
            POST,
            GET,
            DELETE,
            PUT,
            buildUrl,
            buildQueryParamString
        }}>
            {children}
        </HttpClientContext.Provider>
    )
};

